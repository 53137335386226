@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overflow: auto;
  height: 100%;
  margin: 0;
  padding: 0;
}
